<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-wrench"></i> การตั้งค่า </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">

          <div class="card custom-card">
            <div class="card-body">
              <b-tabs pills card vertical>
                <b-tab title="เทมเพลตสำหรับลูกค้า" active>
                  <b-card-text>
                    <b-form-textarea  id="textarea-rows"
                                    v-model="templateCustomer.value"
                                    placeholder="เทมเพลตสำหรับลูกค้า"
                                    rows="50"
                                  ></b-form-textarea>
                  </b-card-text>
                  <div class="float-right">
                    <b-button size="md"
                              text="Button"
                              variant="primary"
                              class="style_btn"
                              style="width: 110px;"
                              @click="editHandler('customer')" v-if="permission.actionData.flag_update">
                      บันทึกใหม่
                    </b-button>
                  </div>
                </b-tab>

                <b-tab title="เทมเพลตสำหรับซัพพลายเออร์">
                  <b-card-text>
                    <b-form-textarea  id="textarea-rows"
                                    v-model="templateSupplier.value"
                                    placeholder="เทมเพลตสำหรับซัพพลายเออร์"
                                    rows="50"
                                  ></b-form-textarea>
                  </b-card-text>
                  <div class="float-right">
                    <b-button size="md"
                              text="Button"
                              variant="primary"
                              class="style_btn"
                              style="width: 110px;"
                              @click="editHandler('supplier')" v-if="permission.actionData.flag_update">
                      บันทึกใหม่
                    </b-button>
                  </div>
                </b-tab>
                <b-tab title="เทมเพลตสำหรับทวนออเดอร์">
                  <b-card-text>
                    <b-form-textarea  id="textarea-rows"
                                    v-model="templateBilling.value"
                                    placeholder="เทมเพลตสำหรับทวนออเดอร์"
                                    rows="50"
                                  ></b-form-textarea>
                  </b-card-text>
                  <div class="float-right">
                    <b-button size="md"
                              text="Button"
                              variant="primary"
                              class="style_btn"
                              style="width: 110px;"
                              @click="editHandler('billing')" v-if="permission.actionData.flag_update">
                      บันทึกใหม่
                    </b-button>
                  </div>
                </b-tab>
              </b-tabs>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'settimg-template-index',
    data () {
      return {
        permission:{
          appSlug: 'setting',
          actionData: {}
        },
        templateCustomer: {},
        templateSupplier: {},
        templateBilling: {}
      }
    },
    methods: {
      async editHandler (type) {
        let params = null;
        if(type=='customer'){
          params = this.templateCustomer;
        }else if(type=='supplier'){
          params = this.templateSupplier;
        }else if(type=='billing'){
          params = this.templateBilling;
        }

        if(params){
          if(!params.value){
            this.AlertUtils.alert('warning', "กรุณาระบุข้อความ");
            return;
          }
          const result = await this.HttpServices.putData(`/ab_template/${params.id}`, {templateValue: params.value});
          if(result&&result.status.code=="200"){
            this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
              this.getItem();
            });
          }else{
            this.AlertUtils.alert('warning', result.status.message);
          }
        }
      },

      async getItem(){
        const result = await this.HttpServices.getData(`/ab_template`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          for(const v of data){
            if(v.name=='template_customer'){
              this.templateCustomer = v;
            }else if(v.name=='template_supplier'){
              this.templateSupplier = v;
            }else if(v.name=='template_bill'){
              this.templateBilling = v;
            }

            
          }
        }else{
          this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูล`, ()=>{
            this.$router.push('/');
          });
        }
      }
    },
    async mounted() {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      await this.getItem();
    }
  };
</script>
<style scoped>
</style>
